<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row>
                    <a-col :span="24">
                        <p class="text">{{ l('Issue_Config') }}</p>
                    </a-col>

                    
                    <a-col :span="24">
                        <a-checkbox v-model="remark">
                            {{ l('Fill_in_the_remark_before_closing_the_question') }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsRequiredIssueResp">
                            {{ l('WhetherThePersonResponsibleForTheQuestionIsMandatory') }}
                        </a-checkbox>
                    </a-col>

                    

                    <a-col :span="24" style="display: flex;">
                        <p style="margin: auto 0;">{{ l('Issue_progress_reminders') + '：' }}</p>
                        <a-select :filterOption="filterOption" style="width: 150px;margin-left: 10px;"
                            v-model="Progress" :placeholder="l('issueprogress.Progress')" @change="issueProgressChange"
                            allow-clear>
                            <a-select-option v-for="item in issueProgressList" :key="item.progress" :title="item.name">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="24">
                       <span>问题必填设置:</span>
                    </a-col>
                    <a-col :span="24">
                        <table border="1">
                            <tr>
                                <td></td>
                                <td>提交时</td>
                                <td>更新时</td>
                                <td>关闭时</td>
                            </tr>
                            <tr>
                                <td>计划日期</td>
                                <td>
                                    <a-checkbox v-model="EnableSubmitPlanTime">
                                    </a-checkbox>
                                </td>
                                <td>
                                    <a-checkbox v-model="EnableUpdatePlanTime">
                                    </a-checkbox>
                                </td>
                                <td>
                                    <a-checkbox v-model="EnableClosePlanTime">
                                    </a-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>改善照片</td>
                                <td>
                                    <a-checkbox v-model="EnableSubmitImprovePicture">
                                    </a-checkbox>
                                </td>
                                <td>
                                    <a-checkbox v-model="EnableUpdateImprovePicture">
                                    </a-checkbox>
                                </td>
                                <td>
                                    <a-checkbox v-model="EnableCloseImprovePicture">
                                    </a-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>改善措施</td>
                                <td>
                                    <a-checkbox v-model="EnableSubmitCorrectiveNote">
                                    </a-checkbox>
                                </td>
                                <td>
                                    <a-checkbox v-model="EnableUpdateCorrectiveNote">
                                    </a-checkbox>
                                </td>
                                <td>
                                    <a-checkbox v-model="EnableCloseCorrectiveNote">
                                    </a-checkbox>
                                </td>
                            </tr>
                        </table>
                    </a-col>

                    <a-col :span="24">
                        <p class="text">{{ l('安全隐患配置') }}</p>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnabledAssistantConfirmationProcess">
                            {{ l('是否启用专员确认流程') }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24" style="display: flex;margin-bottom: 5px;">
                        <p style="margin: auto 0;">{{ l('问题确认专员') + '：' }}</p>
                        <a-select showSearch mode="multiple" style="width: 200px;text-align: left;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            v-model="IssueConfirmAssistant" :placeholder="l('please_enter_your_username_to_search')"
                            allow-clear optionFilterProp="label">
                            <a-select-option v-for="item in userlist" :key="item.id" :label="item.realName">
                                {{ item.realName }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="24" style="display: flex;margin-bottom: 5px;">
                        <p style="margin: auto 0;">{{ l('计划完成时间未填写时长') + '：' }}</p>
                        <a-input-number v-model="IssuePlanTimeNotFilledInHour" style="width: 100px;" :min="0" :max="60"
                            :formatter="value => `${value}`" :parser="value => value.replace('秒', '')"></a-input-number>
                    </a-col>


                    <a-col :span="24" style="margin-top: 30px;">
                        <p class="text">{{ l('Audit_settings') }}</p>
                    </a-col>

                    

                    <a-col :span="24">
                        <a-checkbox v-model="IsIssueFeedbackButton">
                            {{ l('Whether_to_enable_the_issue_feedback_button') }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="Isnonconformances">
                            {{ l('Whether_nonconformances_are_enabled') }}
                        </a-checkbox>
                    </a-col>

                    <a-col :span="24" style="margin-top: 30px;">
                        <p class="text">{{ l('Audit_task_settings') }}</p>
                    </a-col>

                    <a-col :span="24">
                        <a-checkbox v-model="IsEnabledAuditTaskTransfer">
                            {{ l('是否启用任务转办') }}
                        </a-checkbox>
                    </a-col>

                    <br>

                    <a-col :span="24">
                        <a-checkbox v-model="IsEnabled.filedExt1">
                            {{ l('Whether_to_enable_extended_fields', 1) }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnabled.filedExt2">
                            {{ l('Whether_to_enable_extended_fields', 2) }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnabled.filedExt3">
                            {{ l('Whether_to_enable_extended_fields', 3) }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnabled.templateBarCode">
                            {{ l('Whether_barcodes_are_enabled') }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnabled.assitField">
                            {{ l('Whether_to_enable_secondary_fields') }}
                        </a-checkbox>
                    </a-col>

                    <a-col :span="24" style="margin-top: 30px;">
                        <p class="text">{{ l('PictureSettings') }}</p>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnableUploadPicture">
                            {{ l('Whether_to_disable_image_upload') }}
                        </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnableImagesFromAlbums">
                            {{ l('Disable_selecting_images_from_albums') }}
                        </a-checkbox>
                    </a-col>

                    <a-col :span="24" style="margin-top: 30px;">
                        <p class="text">{{ l('VideoSettings') }}</p>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnableOpenPhoneAlbum">
                            {{ l('Enable_video_shooting') }}
                        </a-checkbox>
                    </a-col>

                    <a-col :span="24">
                        <a-checkbox v-model="IsEnableUploadVideo">
                            {{ l('Enable_video_selection_from_album') }}
                        </a-checkbox>
                    </a-col>

                    <a-col :span="24" style="display: flex;">
                        <p style="margin: auto 0;">{{ l('视频时长') + '：' }}</p>
                        <a-input-number v-model="VideoDuration" style="width: 100px;" :min="0" :max="60"
                            :formatter="value => `${value}秒`"
                            :parser="value => value.replace('秒', '')"></a-input-number>
                    </a-col>

                    <a-col :span="24" style="display: flex;margin-top: 10px;">
                        <p style="margin: auto 0;">{{ l('视频质量') + '：' }}</p>
                        <a-select :filterOption="filterOption" style="width: 150px;margin-left: 10px;"
                            v-model="VideoResolution" :placeholder="l('视频质量配置')" @change="VideoResolutionChange"
                            allow-clear>
                            <a-select-option v-for="item in VideoResolutionList" :key="item.itemDetailCode"
                                :title="item.itemDetailName">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :span="24" style="margin-top: 10px;">
                        <img src="../../../../public/img/image/resolutionRatio.png" />
                    </a-col>

                    <a-col :span="24" style="margin-top: 30px;">
                        <p class="text">{{ l('部门报表设置') }}</p>
                    </a-col>
                    <a-col :span="24">
                        <a-checkbox v-model="IsEnableStatisticsOrg">
                            {{ l('是否启用部门归属') }}
                        </a-checkbox>
                    </a-col>

                    <a-col :span="24" style="display: flex;margin-top: 5px;">
                        <div style="line-height: 50px;">
                            <span>
                                {{ l('部门归属配置') + '：' }}
                            </span>
                        </div>
                        <a-input v-model="StatisticsOrgConfig" class="input"
                            style="color: black;width: 300px; margin: 10px 10px 0 0; "
                            :placeholder="l('部门归属配置')"></a-input>
                    </a-col>

                    <a-col :span="24" style="margin-top: 15px;">
                        <a-button :type="'primary'" @click="save()" v-if="isGranted('other_save')">
                            <a-icon type="save" />
                            <span>{{ l('Save') }}</span>
                        </a-button>
                    </a-col>
                </a-row>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
import {
    AppComponentBase
} from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { Dic, fileDownloadService } from "../../../shared/utils";
import {
    IssueServiceProxy,
    EHSServiceProxy,
    SystemConfigServiceProxy,
    IssueProgressServiceProxy,
    EmployeeServiceProxy,
    AuditTaskServiceProxy
} from '../../../shared/service-proxies';
import {
    ModalHelper
} from '@/shared/helpers';

let _this;
export default {
    name: "issue-config",
    mixins: [AppComponentBase],
    components: {

    },
    created() {
        _this = this;
        this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
        this.EHSServiceProxy = new EHSServiceProxy(this.$apiUrl, this.$api);
        this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
        this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
        this._EmployeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
        this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
    },
    async mounted() {
        this.VideoResolutionList = await Dic.getInstance().getDicAsync('VideoResolution');
        this.getissueProgress();
        this.getData();
        this.getuser();
        this.getRemark();
        this.getIsRequiredIssueResp();
        this.getIsEnableUploadPicture();
        this.getIsEnableImagesFromAlbums();
        this.getIsIssueFeedbackButton();
        this.getIsnonconformances();
        this.getIsIssueNotificaationFeature();
        this.getIsExtendedFields();
        this.getIsEnableStatisticsOrg();
        this.getStatisticsOrgConfig();
        this.getIsEnableOpenPhoneAlbum();
        this.getIsEnableUploadVideo();
        this.getVideoDuration();
        this.getVideoResolution();
        this.getIsEnabledAssistantConfirmationProcess();
        this.getIssueConfirmAssistant();
        this.getIssuePlanTimeNotFilledInHour();
        this.getIssueRestrictedFields();
        this.GetIsEnabledAuditTaskTransfer();
    },
    data() {
        return {
            zh_CN,
            isEdit: false,
            isLook: false,
            isLoading: false,
            remark: false,
            date: {},
            remarkDate: {},
            IsRequiredIssueResp: false,
            Privatekey: undefined,
            IsEnableUploadPicture: false,
            IsIssueFeedbackButton: false,
            Isnonconformances: false,
            Progress: undefined,
            issueProgressList: [],
            IsEnabled: {},
            IsEnableStatisticsOrg: false,
            StatisticsOrgConfig: undefined,
            IsEnableOpenPhoneAlbum: false,
            IsEnableUploadVideo: false,
            IsEnableImagesFromAlbums: false,
            VideoDuration: 60,
            VideoResolution: undefined,
            VideoResolutionList: [],
            userlist: [],
            userid: undefined,
            IsEnabledAssistantConfirmationProcess: false,
            IssueConfirmAssistant: undefined,
            IssuePlanTimeNotFilledInHour: 24,
            EnableSubmitPlanTime:false,
            EnableUpdatePlanTime:false,
            EnableClosePlanTime:false,
            EnableSubmitImprovePicture:false,
            EnableUpdateImprovePicture:false,
            EnableCloseImprovePicture:false,
            EnableSubmitCorrectiveNote:false,
            EnableUpdateCorrectiveNote:false,
            EnableCloseCorrectiveNote:false,

            IsEnabledAuditTaskTransfer:true,
        }
    },
    methods: {

        getuser() {
            this._EmployeeServiceProxy.getAllEmp().finally(() => {

            }).then((res) => {
                this.userlist = res;
            })
        },
        //获取关闭问题前为否填写备注
        getRemark() {
            this.isLoading = true;
            this.IssueServiceProxy.getIsCloseIssueFillInRemarks(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                this.remark = res;
            })
        },

        //获取进度值
        getissueProgress() {
            this.isLoading = true;
            this.IssueProgressServiceProxy.getPaged().finally(() => {
                this.isLoading = false;
            }).then(res => {
                console.log(res);
                this.issueProgressList = res.items;
            })
        },

        //获取问题责任人是否必填
        getIsRequiredIssueResp() {
            this.isLoading = true;
            this.IssueServiceProxy.getIsRequiredIssueResp(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                this.IsRequiredIssueResp = res;
            })
        },

        //获取是图片上传是否禁用
        getIsEnableUploadPicture() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getIsEnableUploadPicture(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                this.IsEnableUploadPicture = !res;
            })
        },

        //获取是图片上传是否禁用
        getIsEnableImagesFromAlbums() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getIsEnableUploadPictureForPhone(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                this.IsEnableImagesFromAlbums = res;
            })
        },

        //获取是否启用问题反馈按钮
        getIsIssueFeedbackButton() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getIsEnableAuditPointFeedback(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                this.IsIssueFeedbackButton = res;
            })
        },

        //获取是否启用不符合项
        getIsnonconformances() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getIsEnableAuditTaskDetailDeviationType(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                this.Isnonconformances = res;
            })
        },

        //是否启用带关闭问题通知
        getIsIssueNotificaationFeature() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getToBeClosedIssueNoticeProgress(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                if (res == -1) {
                    this.Progress = undefined;
                }
                else {
                    this.Progress = res;
                }
            })
        },

        //审核任务
        getIsExtendedFields() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getFiledExtSettings(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                this.IsEnabled = res;
            })
        },

        //获取是否启用部门归属配置
        getIsEnableStatisticsOrg() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getIsEnableStatisticsOrg(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.IsEnableStatisticsOrg = res;
                console.log(res);
            })
        },

        //获取部门归属配置
        getStatisticsOrgConfig() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getStatisticsOrgConfig(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.StatisticsOrgConfig = res;
                console.log(res);
            })
        },

        //是否启用打开手机相册
        getIsEnableOpenPhoneAlbum() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getIsEnableOpenPhoneAlbum(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.IsEnableOpenPhoneAlbum = res;
            })
        },

        //是否启用上传视频
        getIsEnableUploadVideo() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getIsEnableUploadVideo(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.IsEnableUploadVideo = res;
            })
        },

        //视频时长
        getVideoDuration() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getVideoDuration(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.VideoDuration = res;
            })
        },

        //视频分辨率
        getVideoResolution() {
            this.isLoading = true;
            this.SystemConfigServiceProxy.getVideoResolution(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.VideoResolution = res;
            })
        },


        //是否启用上传视频
        getIsEnabledAssistantConfirmationProcess() {
            this.isLoading = true;
            this.IssueServiceProxy.getIsEnabledAssistantConfirmationProcess(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.IsEnabledAssistantConfirmationProcess = res;
            })
        },

        //视频时长
        getIssueConfirmAssistant() {
            this.isLoading = true;
            this.IssueServiceProxy.getIssueConfirmAssistant(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                if (res.length != 0) {
                    this.IssueConfirmAssistant = res.split(","); // 使用逗号加空格作为分隔符来切分字符串
                    for (let index = 0; index < this.IssueConfirmAssistant.length; index++) {
                        this.IssueConfirmAssistant[index] = parseInt(this.IssueConfirmAssistant[index]);
                    }
                }

            })
        },

        //视频分辨率
        getIssuePlanTimeNotFilledInHour() {
            this.isLoading = true;
            this.IssueServiceProxy.getIssuePlanTimeNotFilledInHour(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.IssuePlanTimeNotFilledInHour = res;
            })
        },


        VideoResolutionChange(value) {
            this.VideoResolution = value;
        },

        //
        getIssueRestrictedFields() {
            this.isLoading = true;
            this.IssueServiceProxy.getIssueRestrictedFields(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.EnableSubmitPlanTime = res.enableSubmitPlanTime;
                this.EnableUpdatePlanTime = res.enableUpdatePlanTime;
                this.EnableClosePlanTime = res.enableClosePlanTime;
                this.EnableSubmitImprovePicture = res.enableSubmitImprovePicture;
                this.EnableUpdateImprovePicture = res.enableUpdateImprovePicture;
                this.EnableCloseImprovePicture = res.enableCloseImprovePicture;
                this.EnableSubmitCorrectiveNote = res.enableSubmitCorrectiveNote;
                this.EnableUpdateCorrectiveNote = res.enableUpdateCorrectiveNote;
                this.EnableCloseCorrectiveNote = res.enableCloseCorrectiveNote;
            })
        },


        //是否启用上传视频
        GetIsEnabledAuditTaskTransfer() {
            this.isLoading = true;
            this.AuditTaskServiceProxy.getIsEnabledAuditTaskTransfer(

            ).finally(() => {
                this.isLoading = false;

            }).then(res => {
                this.IsEnabledAuditTaskTransfer = res;
            })
        },

        save() {
            

            //问题关闭前填写备注
            this.remarkDate.enable = this.remark;
            this.isLoading = true;
            this.IssueServiceProxy.isCloseIssueFillInRemarks(
                this.remarkDate
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //问题责任人是否必填
            this.isLoading = true;
            this.IssueServiceProxy.isRequiredIssueResp(
                { Enable: this.IsRequiredIssueResp }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否禁用图片上传
            this.isLoading = true;
            this.SystemConfigServiceProxy.isEnableUploadPicture(
                { Enable: !this.IsEnableUploadPicture }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否禁用从相册选择图片
            this.SystemConfigServiceProxy.isEnableUploadPictureForPhone(
                { Enable: this.IsEnableImagesFromAlbums }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否启用待关闭问题通知功能
            this.isLoading = true;
            this.SystemConfigServiceProxy.toBeClosedIssueNoticeProgress(
                { Progress: this.Progress }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否启用问题反馈按钮
            this.isLoading = true;
            this.SystemConfigServiceProxy.isEnableAuditPointFeedback(
                { Enable: this.IsIssueFeedbackButton }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            // 是否启用不符合项
            this.isLoading = true;
            this.SystemConfigServiceProxy.isEnableAuditTaskDetailDeviationType(
                { Enable: this.Isnonconformances }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            // 审核任务
            this.isLoading = true;
            this.SystemConfigServiceProxy.updateFiledExtSettings(
                this.IsEnabled
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否禁用图片上传
            this.isLoading = true;
            this.SystemConfigServiceProxy.isEnableStatisticsOrg(
                { Enable: this.IsEnableStatisticsOrg }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //部门归属配置
            this.isLoading = true;
            this.SystemConfigServiceProxy.statisticsOrgConfig(
                { StatisticsOrgConfig: this.StatisticsOrgConfig }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否启用打开手机相册
            this.isLoading = true;
            this.SystemConfigServiceProxy.isEnableOpenPhoneAlbum(
                { Enable: this.IsEnableOpenPhoneAlbum }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否启用上传视频
            this.isLoading = true;
            this.SystemConfigServiceProxy.isEnableUploadVideo(
                { Enable: this.IsEnableUploadVideo }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //部门归属配置
            this.isLoading = true;
            this.SystemConfigServiceProxy.videoDuration(
                { VideoDuration: this.VideoDuration }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //部门归属配置
            this.isLoading = true;
            this.SystemConfigServiceProxy.videoResolution(
                { VideoResolution: this.VideoResolution }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })


            //是否启用专员确认流程
            this.isLoading = true;
            this.IssueServiceProxy.isEnabledAssistantConfirmationProcess(
                { Enable: this.IsEnabledAssistantConfirmationProcess }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            var  IssueConfirmAssistantstr = "";
            if (this.IssueConfirmAssistant) {
                IssueConfirmAssistantstr = this.IssueConfirmAssistant.join(","); // 使用逗号加空格连接数组元素
            }else{
                IssueConfirmAssistantstr = "";
            }
            
            //问题确认专员
            this.isLoading = true;
            this.IssueServiceProxy.issueConfirmAssistant(
                { IssueConfirmAssistant: IssueConfirmAssistantstr }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //计划完成时间未填写
            this.isLoading = true;
            this.IssueServiceProxy.issuePlanTimeNotFilledInHour(
                { IssuePlanTimeNotFilledInHour: this.IssuePlanTimeNotFilledInHour }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })

            //是否启用专员确认流程
            this.isLoading = true;
            this.IssueServiceProxy.issueRestrictedFields(
                { 
                    EnableSubmitPlanTime: this.EnableSubmitPlanTime,
                    EnableUpdatePlanTime: this.EnableUpdatePlanTime,
                    EnableClosePlanTime: this.EnableClosePlanTime,
                    EnableSubmitImprovePicture: this.EnableSubmitImprovePicture,
                    EnableUpdateImprovePicture: this.EnableUpdateImprovePicture,
                    EnableCloseImprovePicture: this.EnableCloseImprovePicture,
                    EnableSubmitCorrectiveNote: this.EnableSubmitCorrectiveNote,
                    EnableUpdateCorrectiveNote: this.EnableUpdateCorrectiveNote,
                    EnableCloseCorrectiveNote: this.EnableCloseCorrectiveNote

                 }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })


            //是否启用任务转办
            this.isLoading = true;
            this.AuditTaskServiceProxy.isEnabledAuditTaskTransfer(
                { Enable: this.IsEnabledAuditTaskTransfer }
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
            })


            this.$notification.success({
                description: undefined,
                message: this.l('SavedSuccessfully'),
            });
        },

        issueProgressChange(value) {
            this.Progress = value;
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
    }
}
</script>

<style>
.pagination {
    margin: 10px auto;
    text-align: right;
}

.text {
    font-size: 18px;
    font-weight: bold;
}

td{
    width: 80px;
    text-align: center;
    line-height: 30px;
}

</style>